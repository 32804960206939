// returns 00:00 by default

const secondsToString = (seconds: number): string => {
  let numberOfSeconds: number = Math.round(seconds);
  let hoursString = '';
  let minutesString = '00:';
  let secondsString = '00';
  if (numberOfSeconds > 3600) {
    let numberOfHours: number = Math.floor(numberOfSeconds / 3600);
    numberOfSeconds = numberOfSeconds - numberOfHours * 3600;
    hoursString = `${numberOfHours.toString()}:`;
  }
  if (numberOfSeconds > 60) {
    let numberOfMinutes: number = Math.floor(numberOfSeconds / 60);
    numberOfSeconds = numberOfSeconds - numberOfMinutes * 60;
    minutesString = `${numberOfMinutes.toString().padStart(2, '0')}:`;
  }
  secondsString = numberOfSeconds.toString().padStart(2, '0');
  return `${hoursString}${minutesString}${secondsString}`;
};

export default secondsToString;
