import styled from 'styled-components';

import { Button } from '../Button';

export const PlayPauseButton = styled(Button)`
  margin-right: 5%;
  margin-left: 5%;
  width: 28%;
  fill: ${({ theme }) => theme.playPause};
  stroke: ${({ theme }) => theme.playPause};
  &:disabled {
    cursor: default;
    opacity: 0.25;
  }
`;
