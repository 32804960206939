import React, { createRef, useEffect, useRef, useState } from 'react';

import useComponentSize from '@rehooks/component-size';
import { useThrottle } from 'use-throttle';
import { ThemeProvider } from 'styled-components';
import SVG from 'react-inlinesvg';

import * as S from './AudioPlayer.style';
import { log } from '../../utils/log';
import secondsToString from '../../utils/secondsToString';
import PlayPauseButton from '../PlayPauseButton/PlayPauseButton';
import Slider from '../Slider/Slider';
import { rewindButton, forwardButton, volumeButton, muteButton } from '../../img/controls/';
import useAudioPlayer from '../../utils/useAudioPlayer';

import { Config, AudioPlayerProps } from './AudioPlayer.types';

const AudioPlayer: React.FC<AudioPlayerProps> = ({ config: inputConfig, digitalData: inputDigitalData, adConfig: inputAdConfig }) => {
  const containerDiv = createRef<HTMLDivElement>();

  let audioPlayerContainer = useRef(null);
  let audioPlayerContainerSize = useComponentSize(audioPlayerContainer);

  const [{ toggle, changeVolume, changePosition }, { isPlayerReady, isActivated, isPlaying, position, duration, volume }] = useAudioPlayer({
    config: inputConfig,
    adConfig: inputAdConfig,
    digitalData: inputDigitalData,
    containerDiv,
  });

  const [size, setSize] = useState<string>();

  useEffect(() => {
    const { width } = audioPlayerContainerSize;
    if (width > 800) {
      setSize('large');
    } else if (width > 400) {
      setSize('medium');
    } else {
      setSize('small');
    }
  }, [audioPlayerContainerSize]);

  const config: Config = {
    mediaReference: inputConfig.mediaReference,
    aggregatorUrl: inputConfig.aggregatorUrl || 'https://media-services-public.vrt.be/vualto-video-aggregator-web/rest/external/v1',
    theme: {
      text: 'black',
      sliderPrimary: 'black',
      sliderSecondary: 'lightgrey',
      playPause: 'black',
      backForward: 'black',
      volumeMute: 'black',
      ...inputConfig.theme,
    },
  };
  const throttledPosition = useThrottle(position, 200);

  const togglePlayingHandler = () => {
    log('togglePlayingHandler() triggered', new Date().getTime());
    toggle();
  };

  const rewindHandler = () => {
    log('rewind() triggered', new Date().getTime());
    changePosition(position - 10);
  };

  const forwardHandler = () => {
    log('forward() triggered', new Date().getTime());
    changePosition(position + 10);
  };

  const changePositionHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (duration) {
      log('changePosition() triggered', new Date().getTime());
      log('changePosition() event.target.value', event.target.value);
      //the range element returns a value from 0 to 100
      changePosition((duration / 100) * parseInt(event.target.value));
    }
  };

  const changeVolumeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    log('changeVolume() triggered', new Date().getTime());
    log('changeVolume() event.target.value', event.target.value);
    changeVolume(parseInt(event.target.value));
  };

  const currentProgress = duration > 0 ? throttledPosition / (duration / 100) : 0;

  return (
    <ThemeProvider theme={config.theme}>
      <S.AudioPlayerContainer ref={audioPlayerContainer} size={size}>
        {!isPlayerReady && <div>Aan het laden...</div>}
        {isPlayerReady && (
          <S.AudioPlayerContent size={size}>
            <S.PositionContainer>
              <S.PositionDisplay>{secondsToString(throttledPosition)}</S.PositionDisplay>
              <Slider
                tabIndex={-1}
                value={currentProgress}
                onChange={changePositionHandler}
                trackHeight={4}
                handleSize={16}
                disabled={!isActivated}></Slider>
              <S.DurationDisplay>{duration > 0 ? secondsToString(duration) : '--:--'}</S.DurationDisplay>
            </S.PositionContainer>
            <S.BottomContainer size={size}>
              <S.Bottom size={size}>
                <S.InvisibleHelperDiv />
                <S.ControlContainer size={size}>
                  <S.RewindButton tabIndex={isActivated ? 0 : -1} onClick={rewindHandler} role="button" disabled={!isActivated}>
                    <SVG src={rewindButton} />
                  </S.RewindButton>
                  <PlayPauseButton onClick={togglePlayingHandler} currentFunction={isPlaying ? 'pause' : 'play'}></PlayPauseButton>
                  <S.ForwardButton tabIndex={isActivated ? 0 : -1} onClick={forwardHandler} disabled={!isActivated}>
                    <SVG src={forwardButton} />
                  </S.ForwardButton>
                </S.ControlContainer>
                <S.VolumeContainer size={size}>
                  <S.VolumeButton tabIndex={isActivated ? 0 : -1} onClick={() => changeVolume(0)} disabled={!isActivated}>
                    <SVG src={volume === 0 ? muteButton : volumeButton} />
                  </S.VolumeButton>
                  <Slider
                    tabIndex={isActivated ? 0 : -1}
                    value={volume}
                    onChange={changeVolumeHandler}
                    trackHeight={4}
                    handleSize={12}
                    disabled={!isActivated}
                  />
                </S.VolumeContainer>
              </S.Bottom>
            </S.BottomContainer>
          </S.AudioPlayerContent>
        )}
        <S.InvisiblePlayerContainer>
          <div ref={containerDiv} />
        </S.InvisiblePlayerContainer>
      </S.AudioPlayerContainer>
    </ThemeProvider>
  );
};

export default AudioPlayer;
