import styled from 'styled-components';

import { StyledSliderProps } from './Slider.types';

const thumb = ({ theme: { sliderPrimary }, handleSize, trackHeight }: StyledSliderProps) => `
  -webkit-appearance: none;
  border: none;
  appearance: none;
  width: ${handleSize}px;
  height: ${handleSize}px;
  background: ${sliderPrimary};
  border-radius: 50%;
  margin-top: -${handleSize / 2 - trackHeight / 2}px
`;

const track = ({ theme: { sliderPrimary, sliderSecondary }, value, trackHeight }: StyledSliderProps) => `
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: ${trackHeight}px;
  border-radius: ${trackHeight / 2}px;
  background: ${sliderSecondary};
  background: linear-gradient(
    to right,
    ${sliderPrimary} ${value}%,
    ${sliderSecondary} 0px
  );
`;

const trackProgress = ({ theme: { sliderPrimary }, trackHeight }: StyledSliderProps) => `
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: ${trackHeight}px;
  border-radius: ${trackHeight / 2}px;
  background: ${sliderPrimary};
`;

export const Slider = styled.input<StyledSliderProps>`
  width: 100%;
  height: ${({ handleSize }) => handleSize}px;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &::-webkit-slider-runnable-track {
    ${props => track(props)}
  }
  &::-moz-range-track {
    ${props => track(props)}
  }
  &::-moz-range-progress {
    ${props => trackProgress(props)}
  }
  &::-ms-track {
    ${props => track(props)}
  }
  &::-ms-fill-lower {
    ${props => trackProgress(props)}
  }

  &::-webkit-slider-thumb {
    margin-top: -6px;
    ${props => thumb(props)}
  }
  &::-moz-range-thumb {
    ${props => thumb(props)}
  }
  &::-ms-thumb {
    ${props => thumb(props)}
    margin-top: 0;
  }

  &::-ms-tooltip {
    display: none;
  }

  &:disabled {
    opacity: 0.55;
    cursor: default;
  }
`;
