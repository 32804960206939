import { useEffect, useState } from 'react';

import { Controls, PlayerProps, State } from './useAudioPlayer.types';

const useAudioPlayer = ({ config: inputConfig, adConfig, digitalData, containerDiv }: PlayerProps): [Controls, State] => {
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [position, setPosition] = useState<number>(0);
  const [volume, setVolume] = useState<number>(80);
  const [duration, setDuration] = useState<number>(0);
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false);
  const [isConfigReady, setIsConfigReady] = useState<boolean>(false);
  const [player, setPlayer] = useState<any>();

  const ThrowPLayerNotReadyError = () => {
    throw new Error('Player not ready');
  };

  const play = (): void => {
    if (!isPlayerReady) {
      ThrowPLayerNotReadyError();
    }
    player.play();
  };
  const pause = (): void => {
    if (!isPlayerReady) {
      ThrowPLayerNotReadyError();
    }
    player.pause();
  };
  const toggle = (): void => {
    if (!isPlayerReady) {
      ThrowPLayerNotReadyError();
    }
    player.togglePlay();
  };
  const changeVolume = (level: number): void => {
    if (!isPlayerReady) {
      ThrowPLayerNotReadyError();
    }
    player.volume = level / 100;
    setVolume(level);
  };
  const changePosition = (position: number): void => {
    if (!isPlayerReady) {
      ThrowPLayerNotReadyError();
    }
    player.fastSeek(position);
  };

  const config = {
    mediaReference: inputConfig.mediaReference,
    aggregatorUrl: inputConfig.aggregatorUrl || 'https://media-services-public.vrt.be/vualto-video-aggregator-web/rest/external/v1',
    playerMode: 'audio',
  };

  const timeUpdateCallback = () => {
    !duration && setDuration(player.duration);
    setPosition(player.currentTime);
  };

  const playCallback = () => {
    !isActivated && setIsActivated(true);
    setIsPlaying(true);
  };

  const pauseCallback = () => {
    setIsPlaying(false);
  };

  const vrtPlayerBootstrappedCallback = () => {
    setIsPlayerReady(true);
  };

  const vrtPlayerBootstrapFailedCallback = () => {
    throw new Error('Player could not be bootstrapped');
  };

  const initPlayer = () => {
    window.VRTMediaPlayer.get(containerDiv.current, config, digitalData, adConfig)
      .then((playerInstance: any) => {
        setPlayer(playerInstance);
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  };

  useEffect(() => {
    if (typeof config.mediaReference !== 'undefined') {
      setIsConfigReady(true);
    } else {
      throw new Error('Media reference not provided');
    }
  }, []);

  useEffect(() => {
    if (!window.playerBootstrapped && !window.playerBootstrapError) {
      window.addEventListener('vrtPlayerBootstrapped', vrtPlayerBootstrappedCallback);
      window.addEventListener('vrtPlayerBootstrapFailed', vrtPlayerBootstrapFailedCallback);
    }
    window.playerBootstrapped && setIsPlayerReady(true);
    return () => {
      window.removeEventListener('vrtPlayerBootstrapped', vrtPlayerBootstrappedCallback);
      window.removeEventListener('vrtPlayerBootstrapFailed', vrtPlayerBootstrapFailedCallback);
    };
  }, []);

  useEffect(() => {
    isConfigReady && isPlayerReady && initPlayer();
  }, [isConfigReady, isPlayerReady]);

  useEffect(() => {
    if (player) {
      player.addEventListener('timeupdate', timeUpdateCallback);
      player.addEventListener('play', playCallback);
      player.addEventListener('pause', pauseCallback);
    }
    return () => {
      if (player) {
        player.removeEventListener('timeupdate', timeUpdateCallback);
        player.removeEventListener('play', playCallback);
        player.removeEventListener('pause', pauseCallback);
      }
    };
  }, [player]);

  const controls: Controls = {
    play,
    pause,
    toggle,
    changeVolume,
    changePosition,
  };
  const state: State = {
    isPlayerReady,
    isActivated,
    isPlaying,
    position,
    duration,
    volume,
  };

  return [controls, state];
};

export default useAudioPlayer;
