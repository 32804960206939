import { AudioPlayer, Config } from 'components/AudioPlayer';
import React from 'react';
import ReactDOM from 'react-dom';

import { log, logError } from './utils/log';

window.addEventListener('vrtPlayerBootstrapped', () => {
  log('Player bootstrap OK', new Date().getTime());
  Object.assign(window, {
    playerBootstrapped: true,
  });
});
window.addEventListener('vrtPlayerBootstrapFailed', () => {
  logError('Player failed to bootstrap');
  Object.assign(window, {
    playerBootstrapError: true,
  });
});

let vrtPlayerScript = document.createElement('script');
//vrtPlayerScript.src = 'https://media-services-public-dev.vrt.be/mediaplayer/js/main.js';
vrtPlayerScript.src = 'https://media-services-public.vrt.be/mediaplayer/js/main.js';
//vrtPlayerScript.src = 'https://media-services-public-stag.vrt.be/mediaplayer/js/main.js';

let header = document.getElementsByTagName('head')[0];
header.appendChild(vrtPlayerScript);

export interface AudioPLayer {
  selector?: string;
  element?: HTMLElement;
  config: Config;
  digitalData?: object;
  adConfig?: object;
}

export const audioPlayer = {
  render: ({ selector, element, config, digitalData, adConfig }: AudioPLayer) => {
    if (typeof selector === 'undefined' && typeof element === 'undefined') {
      throw new Error('Whoops!');
    }

    ReactDOM.render(
      <AudioPlayer config={config} digitalData={!digitalData ? undefined : digitalData} adConfig={!adConfig ? undefined : adConfig} />,
      element ? element : document.querySelector(selector),
    );
  },
};
