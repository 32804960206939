import styled from 'styled-components';

import { Button } from '../Button';

const fontFamily =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export const AudioPlayerContainer = styled.div<{ size: string }>`
  font-family: ${fontFamily};
  font-variant-numeric: tabular-nums;
`;

export const AudioPlayerContent = styled.div<{ size: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const PositionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const PositionDisplay = styled.div`
  color: ${({ theme }) => theme.text};
  margin-right: 0.5em;
  white-space: nowrap;
`;

export const DurationDisplay = styled.div`
  color: ${({ theme }) => theme.text};
  margin-left: 0.5em;
  white-space: nowrap;
`;

export const BottomContainer = styled.div<{ size: string }>`
  position: relative;
  margin: 0.8em auto 0 auto;
  width: ${({ size }) => (size !== 'large' ? '100%' : '75%')};
`;

export const Bottom = styled.div<{ size: string }>`
  display: flex;
  flex-direction: ${({ size }) => (size === 'small' ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
`;

export const InvisibleHelperDiv = styled.div`
  width: 25%;
  margin-right: auto;
`;

export const ControlContainer = styled.div<{ size: string }>`
  width: ${({ size }) => (size === 'small' ? '100%' : '50%')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const RewindButton = styled(Button)`
  width: 14%;
  fill: ${({ theme }) => theme.backForward};
  stroke: ${({ theme }) => theme.backForward};
`;

export const ForwardButton = styled(Button)`
  width: 14%;
  fill: ${({ theme }) => theme.backForward};
  stroke: ${({ theme }) => theme.backForward};
`;

export const VolumeContainer = styled.div<{ size: string }>`
  width: ${({ size }) => (size === 'small' ? '50%' : '25%')};
  margin-left: ${({ size }) => (size === 'small' ? '-0.75em' : '0')};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${({ size }) => (size === 'small' ? '1em' : '0')};
`;

export const VolumeButton = styled(Button)`
  width: 15%;
  margin-right: 10px;
  fill: ${({ theme }) => theme.backForward};
  stroke: ${({ theme }) => theme.backForward};
`;

export const InvisiblePlayerContainer = styled.div`
  display: none;
`;
