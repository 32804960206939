import React from 'react';

import SVG from 'react-inlinesvg';

import * as S from './PlayPauseButton.style';
import { pauseButton, playButton } from '../../img/controls';

const PlayPauseButton = ({ onClick, currentFunction, disabled = false }) => (
  <S.PlayPauseButton onClick={onClick} currentFunction={currentFunction} disabled={disabled}>
    <SVG src={currentFunction === 'pause' ? pauseButton : playButton} />
  </S.PlayPauseButton>
);

export default React.memo(PlayPauseButton);
